<script setup lang="ts">
    import { useAuth } from '@/stores/auth';
    import { consumeRedirectAfterLogin, getRedirectAfterLogin } from '@/utils/localStorage';
    import { onMounted } from 'vue';
    import { useRouter } from 'vue-router';

    const auth = useAuth();
    const router = useRouter();

    const chooseLoginMethod = async () => {
        await auth.activeMsal?.handleRedirectPromise();
        const redirect = getRedirectAfterLogin();

        if (await auth.trySilentLogins()) {
            if (redirect) {
                consumeRedirectAfterLogin();
                router.push(redirect);
            } else {
                router.push('/');
            }
        } else {
            auth.explicitLogin('ENTRA');
        }
    };

    onMounted(chooseLoginMethod);
</script>

<template>{{ '' }}</template>
