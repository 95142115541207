import { type Role, useHasRole } from '@/composables/useHasRole';
import { useAuth } from '@/stores/auth';
import {
    consumeRedirectAfterLogin,
    getRedirectAfterLogin,
    setRedirectAfterLogin,
} from '@/utils/localStorage';
import LoginPage from '@/views/LoginPage.vue';
import { type RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

// special routes
const unmatched = '/:pathMatch(.*)*';
const unguarded = ['/', '/login', '/logout', '/impressum', '/datenschutz'];

const allowRoles =
    (roles: Role | Role[]): RouteRecordRaw['beforeEnter'] =>
    () =>
        useHasRole()(roles) || { path: '/' };

const welcomeRoute = '/welcome';

const router = createRouter({
    history: createWebHistory(),

    routes: [
        {
            path: '/',
            redirect: welcomeRoute,
        },
        {
            path: welcomeRoute,
            name: welcomeRoute,
            component: () => import('@/views/WelcomePage.vue'),
        },

        {
            path: '/datenschutz',
            name: 'datenschutz',
            component: () => import('@/views/DatenschutzPage.vue'),
        },
        {
            path: '/impressum',
            name: 'impressum',
            component: () => import('@/views/ImpressumPage.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: LoginPage,
        },
        {
            path: '/versicherungNewAction',
            name: 'versicherungNewAction',
            component: () => import('@/views/VersicherungNewAction.vue'),
        },
        {
            path: '/versicherungMajorDamage',
            name: 'versicherungMajorDamage',
            component: () => import('@/views/VersicherungMajorDamage.vue'),
        },
        {
            path: '/versicherungPresentAction',
            name: 'versicherungPresentAction',
            component: () => import('@/views/VersicherungPresentAction.vue'),
        },
    ],
});

export const routes = router.getRoutes();
// ---------------------------------------------------------------------------------------------------------------------
// authentication
// ---------------------------------------------------------------------------------------------------------------------

// set up auth and guard routes
router.beforeEach(async (to, from, next) => {
    const auth = useAuth();

    // 404
    if (to.matched[0]?.path === unmatched) {
        return next();
    }

    // guarded
    const guarded = unguarded.every(path => path !== to.path);
    if (guarded && !(await auth.getToken())) {
        setRedirectAfterLogin(to.fullPath);
        const account = await auth.initialize();
        const redirect = getRedirectAfterLogin();
        if (account && to.fullPath === redirect) {
            consumeRedirectAfterLogin();
        }
    }

    next();
});
export default router;
