import { createI18n } from 'vue-i18n';

import de from './de.json';

const messages = {
    de,
};

export type MessageSchema = typeof de;

export const i18n = createI18n<[MessageSchema], 'de'>({
    legacy: false,
    locale: 'de',
    fallbackLocale: 'de',
    messages,
});
