/**
 * We can authenticate to the Application in two different ways, providing
 * great Business to Business and Business to Customer experiences:
 *
 * - entra: Authentication via Microsoft Entra,
 * meaning a login via a company Microsoft-Account.
 * This needs some setup on our side and is great for larger Customers.
 * - B2C: Authentication via Email and Password. Perfect for the typical small customer.
 * Can be invited via their BOAdmin/Kundenadmin.
 *
 * Both Authentication methods are handled by Azure, meaning we don't implement them here,
 * we just redirect to the corresponding tenant and then acquire a token afterwards.
 */
export type AuthType = 'B2C' | 'ENTRA';

const getVariables = (type: AuthType) => {
    if (type === 'ENTRA') {
        return {
            clientId: import.meta.env.VITE_AUTH_ENTRA_CLIENT_ID,
            tenantId: import.meta.env.VITE_AUTH_ENTRA_TENANT_ID,
            scopes: [import.meta.env.VITE_AUTH_ENTRA_SCOPE],
            authority: import.meta.env.VITE_AUTH_ENTRA_AUTHORITY,
        };
    } else {
        return {
            clientId: import.meta.env.VITE_AUTH_B2C_CLIENT_ID,
            tenantId: import.meta.env.VITE_AUTH_B2C_TENANT_ID,
            scopes: [import.meta.env.VITE_AUTH_B2C_SCOPE],
            authority: import.meta.env.VITE_AUTH_B2C_AUTHORITY,
        };
    }
};

const loadConfig = (type: AuthType) => {
    const { clientId, tenantId, scopes, authority } = getVariables(type);
    const msalConfig = {
        auth: {
            clientId,
            authority,
            knownAuthorities: [authority],
            redirectUri: `${location.origin}/auth/redirect/login`,
            postLogoutRedirectUri: `${location.origin}/`,
        },
    };
    return {
        msalConfig,
        scopes,
        tenantId,
    };
};

const b2cConfig = loadConfig('B2C');
const entraConfig = loadConfig('ENTRA');

export const getConfig = (type: AuthType) => (type === 'B2C' ? b2cConfig : entraConfig);
