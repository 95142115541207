/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */
// Plugins
// Types
import { i18n } from '@/locale';
import MyLocale from '@/plugins/locale';
import router from '@/router';
import pinia from '@/stores';
import { VueQueryPlugin } from '@tanstack/vue-query';
import ToastService from 'primevue/toastservice';
import type { App, Plugin } from 'vue';

const inMinutes = (m: number) => m * 60 * 1000;

type PluginDeclaration = { plugin: Plugin; config?: unknown[] };

export const testingPlugins: PluginDeclaration[] = [{ plugin: ToastService }];

const globalPlugins: PluginDeclaration[] = [
    ...testingPlugins,
    { plugin: pinia },
    {
        plugin: VueQueryPlugin,
        config: [
            {
                queryClientConfig: { defaultOptions: { queries: { staleTime: inMinutes(10) } } },
            },
        ],
    },
    { plugin: i18n },
    { plugin: router },
];

export const registerPlugins = (app: App) => {
    globalPlugins.forEach(({ plugin, config }) => app.use(plugin, ...(config ?? [])));
};
