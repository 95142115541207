import axios from 'axios';

import { useAuth } from '@/stores/auth';

const createApi = (baseURL: string) => {
    const instance = axios.create({
        baseURL,
        headers: {
            post: {
                'Content-Type': 'application/json',
            },
            'Access-Control-Allow-Origin': '*',
        },
    });

    instance.interceptors.request.use(
        async config => {
            const auth = useAuth();
            const token = await auth.getToken();
            if (!token) {
                return config;
            }
            config.headers['Authorization'] = 'Bearer ' + token;
            return config;
        },
        error => Promise.reject(error)
    );

    return instance;
};

export const api = createApi(import.meta.env.VITE_API_BASE);
