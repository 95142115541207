<script setup lang="ts">
    import NavSidebar from '@/components/NavSidebar.vue';
    import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
    import { onMounted, ref } from 'vue';
    import { RouterView, useRouter } from 'vue-router';

    const isLocal = ref();

    onMounted(() => {
        isLocal.value = window.location.hostname === 'localhost';
    });

    const { currentRoute } = useRouter();
</script>

<template>
    <div class="flex overflow-hidden">
        <NavSidebar v-if="currentRoute.name !== 'login'" />
        <RouterView class="mx-auto w-full" />
    </div>
    <VueQueryDevtools v-if="isLocal" />
    <Toast position="bottom-left" />
</template>
