import type { AuthType } from '@/config/auth';

export const setRedirectAfterLogin = (path: string) => {
    if (path.includes('login') || path === '/') {
        return;
    }
    localStorage.setItem('redirectAfterLogin', path);
};

export const consumeRedirectAfterLogin = () => {
    const path = localStorage.getItem('redirectAfterLogin');
    localStorage.removeItem('redirectAfterLogin');
    return path;
};

export const getRedirectAfterLogin = () => localStorage.getItem('redirectAfterLogin');

export const clearPreferredAuthType = () => localStorage.removeItem('preferredAuthType');

export const setPreferredAuthType = (type: AuthType) =>
    localStorage.setItem('preferredAuthType', type);

export const getPreferredAuthType = () =>
    localStorage.getItem('preferredAuthType') as AuthType | null;

export const clearForcedAuthType = () => localStorage.removeItem('forcedAuthType');

export const setForcedAuthType = (type: AuthType) => localStorage.setItem('forcedAuthType', type);

export const getForcedAuthType = () => localStorage.getItem('forcedAuthType') as AuthType | null;
