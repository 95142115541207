import { usePrefetchUserInfo } from '@/api/baseInfo';
import { type AuthType, getConfig } from '@/config/auth';
import {
    clearForcedAuthType,
    clearPreferredAuthType,
    getForcedAuthType,
    getPreferredAuthType,
    setForcedAuthType,
    setPreferredAuthType,
} from '@/utils/localStorage';
import {
    type AccountInfo,
    type IPublicClientApplication,
    PublicClientApplication,
} from '@azure/msal-browser';
import { useQueryClient } from '@tanstack/vue-query';
import { isObject } from 'lodash-es';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const accountMatchesType = (type: AuthType) => (account: AccountInfo) =>
    account.tenantId === getConfig(type).tenantId;

export const useAuth = defineStore('auth', () => {
    const router = useRouter();
    const queryClient = useQueryClient();
    const prefetchUserInfo = usePrefetchUserInfo();

    const activeMsal = ref<IPublicClientApplication | null>(null);
    const activeConfig = ref<ReturnType<typeof getConfig> | null>(null);
    const activeAuthType = ref<AuthType | null>(null);

    const initialize = async () => {
        const silentLoginAccount = await trySilentLogins();
        if (silentLoginAccount) {
            return silentLoginAccount;
        }
        router.push('/login');
        return null;
    };

    const trySilentLogin = async (type: AuthType) => {
        const config = getConfig(type);
        const msal = await PublicClientApplication.createPublicClientApplication(config.msalConfig);
        await msal.handleRedirectPromise();

        const accounts = msal.getAllAccounts();

        const account = accounts.find(accountMatchesType(type));

        if (account) {
            msal.setActiveAccount(account);
            activeMsal.value = msal;
            activeConfig.value = config;
            setPreferredAuthType(type);
            clearForcedAuthType();
            activeAuthType.value = type;
            await queryClient.invalidateQueries();
            await prefetchUserInfo();

            return account;
        }
        return null;
    };

    const trySilentLogins = async () => {
        const preferredType = getForcedAuthType() || getPreferredAuthType() || 'B2C';
        const account = await trySilentLogin(preferredType);
        if (account) {
            return account;
        }
        return trySilentLogin(preferredType === 'ENTRA' ? 'B2C' : 'ENTRA');
    };

    const explicitLogin = async (type: AuthType) => {
        const config = getConfig(type);
        const msal = await PublicClientApplication.createPublicClientApplication(config.msalConfig);
        await msal.handleRedirectPromise();

        setForcedAuthType(type);
        console.log({ config });

        try {
            console.log({ msal });
            await msal.loginRedirect({
                redirectUri: config.msalConfig.auth.redirectUri,
                scopes: config.scopes,
            });
        } catch (e: unknown) {
            if (isObject(e) && 'errorCode' in e && e.errorCode === 'interaction_in_progress') {
                sessionStorage.clear();
                await explicitLogin(type);
            }
            console.error(e);
        }
    };

    const attemptLogin = async (type: AuthType) => {
        const silentAccount = await trySilentLogin(type);
        if (silentAccount) {
            return silentAccount;
        } else {
            return explicitLogin(type);
        }
    };

    const logout = async () => {
        if (!activeMsal.value) {
            return;
        }
        clearPreferredAuthType();
        await activeMsal.value.handleRedirectPromise();
        await activeMsal.value.logoutRedirect({ postLogoutRedirectUri: '/login' });
    };

    const getToken = async () => {
        if (!activeMsal.value) {
            return null;
        }
        const request = {
            scopes: activeConfig.value?.scopes ?? [],
        };
        try {
            const res = await activeMsal.value.acquireTokenSilent(request);
            return res.accessToken;
        } catch (e) {
            await initialize();
        }
        return null;
    };

    const isInitialized = () => !!activeMsal.value;

    return {
        attemptLogin,
        initialize,
        logout,
        getToken,
        explicitLogin,
        activeMsal,
        activeAuthType,
        trySilentLogins,
        isInitialized,
    };
});
