import { api } from '@/plugins/axios';
import { useAuth } from '@/stores/auth';
import { useQuery, useQueryClient } from '@tanstack/vue-query';

type UserInfo = { Name: string; Email: string; Rollen: string[] };

export const userInfoQueryKey = 'userInfo';

const _userQueryFn = async () => (await api.get<UserInfo>('Benutzer')).data;
const userQueryFn = async () => {
    return {
        Name: 'Test User',
        Email: 't.terminfindung@boservice.de',
        Rollen: ['User'],
    };
};

export const useUserInfo = () => {
    const auth = useAuth();
    return useQuery<UserInfo, string>({
        queryKey: [userInfoQueryKey],
        staleTime: Infinity,
        enabled: auth.isInitialized,
        queryFn: userQueryFn,
    });
};

/**
 * Prefetch is needed as part of the silent login.
 *
 * When we log in silently we await the initialization in the router.
 * We need to already fetch the roles there, so a guarded route can access them.
 * If we wouldn't do this, we would always get redirected to /.
 */
export const usePrefetchUserInfo = () => {
    const queryClient = useQueryClient();
    return () =>
        queryClient.prefetchQuery({
            queryKey: [userInfoQueryKey],
            staleTime: Infinity,
            queryFn: userQueryFn,
        });
};
