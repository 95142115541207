<script setup lang="ts">
    import { useUserInfo } from '@/api/baseInfo';
    import { type HeaderItem, useAccessible } from '@/composables/useHasRole';
    import { useAuth } from '@/stores/auth';
    import Button from 'primevue/button';
    import { computed, ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { type RouteLocationRaw, useRoute, useRouter } from 'vue-router';

    import MenuLinks from './MenuLinks.vue';

    const auth = useAuth();

    const { t } = useI18n();
    const accessible = useAccessible();

    const { data: user } = useUserInfo();

    const route = useRoute();
    const router = useRouter();
    const navigate = (to: RouteLocationRaw) => () => {
        router.push(to);
    };

    // User Dropdown

    const dropdownItems = [
        {
            label: t('infoPages.imprint.headline'),
            icon: 'pi pi-fw pi-file',
            command: () => window.open('https://buo.de/service/impressum/', '_blank'),
        },
        {
            label: t('infoPages.gdpr.headline'),
            icon: 'pi pi-fw pi-shield',
            command: navigate('/datenschutz'),
        },
        {
            label: t('header.logout'),
            icon: 'pi pi-fw pi-sign-out',
            roles: 'all',
            command: () => auth.logout(),
        },
    ] satisfies HeaderItem[];

    const accessibleDropdownItems = accessible(dropdownItems);

    // VARIABLE
    const menu = ref();
    const isDropdownVisible = ref(false);

    // FUNCTIONS
    const toggle = (event: MouseEvent) => {
        menu.value.toggle(event);
        isDropdownVisible.value = !isDropdownVisible.value;
    };

    const visible = ref(false);

    const baseUserItems = [
        {
            label: t('auftragsverwaltung.navbar'),
            key: '/welcome',
            roles: 'all',
            icon: 'pi pi-search',
            tooltip: t('auftragsverwaltung.navbar'),
        },
    ] satisfies HeaderItem[];

    const userItems = accessible(baseUserItems);

    const selectedMenuItem = computed(
        () => userItems.value.find(mI => mI.key === route.name) ?? userItems.value[0]
    );
</script>

<template>
    <aside
        class="relative flex h-screen w-60 flex-col border-r-2 border-primary-100 py-4 transition-all"
        :class="{ 'w-[4rem]  justify-center px-0': visible === false }"
    >
        <div class="relative flex h-20 w-24">
            <Transition name="fade">
                <img
                    v-if="visible"
                    :alt="t('header.boLogoAltText')"
                    src="@/assets/img/BOLogo.png"
                    class="absolute left-4 top-0"
                />

                <img
                    v-else
                    :alt="t('header.boLogoAltText')"
                    src="/Logo_B&O_Service2.png"
                    class="absolute left-4 top-0 w-8"
                />
            </Transition>
        </div>

        <!-- Menu Links -->

        <div class="w-full grow">
            <MenuLinks
                :items="userItems"
                :visible="visible"
                :selected-menu-item="selectedMenuItem"
                :headline="t('auftragsverwaltung.headline')"
                headline-icon="pi pi-book"
            />
        </div>
        <div class="user-profile space-y-2">
            <div class="relative pl-4">
                <img
                    :alt="t('header.customerLogoAltText')"
                    src="/Logo_B&O_Service2.png"
                    class="h-8 w-8 object-contain transition-all"
                />
                <Button
                    icon="pi pi-angle-down"
                    link
                    aria-haspopup="false"
                    aria-controls="overlay_menu"
                    :aria-label="t('general.toggleOverlayMenu')"
                    class="absolute -top-10 left-4 h-8 w-8 rounded-full bg-primary-100 transition-all duration-200"
                    :class="{
                        '-rotate-180': isDropdownVisible,
                        'left-[3.5rem] top-[0rem] translate-x-0': visible,
                    }"
                    @click="toggle"
                />
                <Menu
                    id="overlay_menu"
                    ref="menu"
                    :model="accessibleDropdownItems"
                    :popup="true"
                    class="custom"
                />
            </div>

            <p class="h-4 truncate text-ellipsis px-4 text-sm text-primary">
                <Transition name="fade">
                    <span v-if="visible">{{ user?.Email }}</span>
                </Transition>
            </p>
        </div>
        <button
            class="absolute right-0 top-0 z-20 rounded-bl-md bg-primary-100 p-2 transition-all"
            :class="{ 'translate-x-full  rounded-bl-none rounded-ee-md ': visible === false }"
            :aria-label="t('general.toggleSidebar')"
            data-testid="toggleSidebar"
            @click="visible = !visible"
        >
            <i class="pi pi-angle-double-right transition-all" :class="{ 'rotate-180': visible }" />
        </button>
    </aside>
</template>

<style scoped>
    .fade-enter-active {
        transition: all 0.15s ease-in 0.1s;
    }
    .fade-leave-active {
        transition: all 0.03s ease-out;
    }

    .fade-enter-from {
        opacity: 0;
    }

    .fade-leave-to {
        opacity: 0;
    }
</style>
