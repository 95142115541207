import { registerComponents } from '@/components';

/*  Plugins */
import { registerPlugins } from '@/plugins';
import '@/styles/index.css';
import { createApp } from 'vue';

import App from './App.vue';
import { initPrimeVue } from './plugins/primevue';

const app = createApp(App);

registerPlugins(app);
registerComponents(app);
initPrimeVue(app);

app.mount('#app');
