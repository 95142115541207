import { useUserInfo } from '@/api/baseInfo';
import type { MenuItem } from 'primevue/menuitem';
import { type ComputedRef, computed } from 'vue';

export type Role = 'Benutzer' | 'Kundenadmin' | 'BOAdmin' | 'all';
export const allRoles: Role[] = ['Benutzer', 'Kundenadmin', 'BOAdmin'];

export type HeaderItem = MenuItem & { roles?: Role | Role[] };

export const useRolesToModify = () => {
    const hasRole = useHasRole();
    const isBOAdmin = hasRole('BOAdmin');
    return isBOAdmin ? allRoles : allRoles.filter(r => r !== 'BOAdmin');
};

export const useHasRole = () => {
    const { data: user } = useUserInfo();
    return (roleOrRoles: Role | Role[]) => {
        if (!user.value) {
            return false;
        }
        const roles = typeof roleOrRoles === 'string' ? [roleOrRoles] : roleOrRoles;
        if (roles.includes('all')) return true;
        return roles.some(role => user.value.Rollen.includes(role));
    };
};

export const useHasAccess = () => {
    const hasRole = useHasRole();
    return (hi: HeaderItem) => !hi.roles || hasRole(hi.roles);
};

export const useAccessible = () => {
    const hasAccess = useHasAccess();
    return (items: HeaderItem[]): ComputedRef<HeaderItem[]> =>
        computed(() => items.filter(hasAccess));
};
