/* Primevue components */
import Avatar from 'primevue/avatar';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import PrimevueDialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import MultiSelect from 'primevue/multiselect';
import Paginator from 'primevue/paginator';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import SelectButton from 'primevue/selectbutton';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToggleButton from 'primevue/togglebutton';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
// Types
import type { App } from 'vue';

export const testingComponents = {
    Button,
    PrimevueDialog,
    ScrollPanel,
    RadioButton,
    InputText,
    TreeSelect,
    Divider,
    ScrollTop,
};

const globalComponents = {
    ...testingComponents,
    Card,
    TabMenu,
    InputNumber,
    DataTable,
    Menu,
    Column,
    Menubar,
    Avatar,
    Carousel,
    Tag,
    Paginator,
    TreeTable,
    ProgressBar,
    ProgressSpinner,
    Timeline,
    ToggleButton,
    Fieldset,
    Toast,
    SelectButton,
    Textarea,
    Checkbox,
    Image,
    MultiSelect,
    FileUpload,
};

export const registerComponents = (app: App) =>
    Object.entries(globalComponents).forEach(([name, component]) => app.component(name, component));
