<script setup lang="ts">
    import type { HeaderItem } from '@/composables/useHasRole';
    import { RouterLink, useRouter } from 'vue-router';

    defineProps<{
        items: HeaderItem[];
        selectedMenuItem?: HeaderItem;
        visible?: boolean;
        headline?: string;
        headlineIcon?: string;
    }>();
    const router = useRouter();

    const navigate = (name?: string) => {
        if (name) {
            router.push({ name });
        }
    };
</script>

<template>
    <div v-if="items.length">
        <h3
            class="mb-4 mt-4 flex h-10 items-center space-x-2 whitespace-nowrap bg-primary-100 px-4 py-2 text-xs tracking-widest"
        >
            <Transition name="fade">
                <i
                    v-if="!visible"
                    class="text-white flex-shrink-0 p-2 text-center text-[1rem] transition-all"
                    :class="headlineIcon"
                />

                <span v-else class="">
                    {{ headline }}
                </span>
            </Transition>
        </h3>
        <ul class="flex flex-col space-y-4 px-4">
            <li v-for="item in items" :key="item.key" @click="navigate(item.key)">
                <router-link
                    v-if="item.key"
                    :to="item.key"
                    class="group flex cursor-pointer items-center space-x-2 whitespace-nowrap font-bold text-primary"
                >
                    <i
                        v-if="!visible"
                        v-tooltip.right="item.tooltip"
                        class="p-1 text-center text-[1.5rem] text-primary-100 transition-all group-hover:text-secondary"
                        :class="item.icon"
                    />
                    <i
                        v-else
                        class="p-1 text-center text-[1.5rem] text-primary-100 transition-all group-hover:text-secondary"
                        :class="item.icon"
                    />
                    <Transition name="fade">
                        <span v-if="visible" class="mr-auto group-hover:text-secondary">
                            {{ item.label }}
                        </span>
                    </Transition>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<style scoped>
    .fade-enter-active {
        transition: all 0.25s ease-out 0.1s;
    }
    .fade-leave-active {
        transition: all 0.05s linear;
    }

    .fade-enter-from {
        opacity: 0;
    }

    .fade-leave-to {
        opacity: 0;
        translate: -10px 0;
    }

    .router-link-active > * {
        @apply text-secondary;
    }
</style>
